import { React, useEffect, useState, useRef } from "react";
import "react-datetime/css/react-datetime.css";
import axios from "axios";
import { Calendar } from "primereact/calendar";
import { useHistory, useLocation } from "react-router-dom";
import Utils from "../../Utils";
import { Toast } from "primereact/toast";
import moment from "moment";

export default function AddEditPromotion() {
  const toast = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const defaultStartDate = new Date();

  defaultStartDate.setHours(0);
  defaultStartDate.setMinutes(0);
  defaultStartDate.setSeconds(0);

  const defaultEndDate = new Date();
  defaultEndDate.setHours(23);
  defaultEndDate.setMinutes(59);
  defaultEndDate.setSeconds(59);

  const [suppliers, setsuppliers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [shopsArray, setShopsArray] = useState([]);
  const [promotionProductsArray, setPromotionProductsArray] = useState([]);
  const [editPromotionID, setEditPromotionID] = useState();
  const [formData, setFormData] = useState({
    Promotion_ID: 0,
    PromotionType: "",
    PromotionName: "",
    InternalPromotionName: "",
    BuyQty: "1",
    FreeQty: "0",
    PromotionPrice: "0",
    PromotionDiscount: "0",
    IsPromotionBySupplier: false,
    IsForLoyalCustomerOnly: false,
    SupplierId: "0",
    ProductBarcode: "",
    PromotionStartDate: defaultStartDate,
    PromotionEndDate: defaultEndDate,
    PromotionStartTime: defaultStartDate,
    PromotionEndTime: defaultEndDate,

    PromotionStartDateString: moment(defaultStartDate).format(
      "YYYY-MM-DD HH:mm:ss"
    ),
    PromotionEndDateString: moment(defaultEndDate).format(
      "YYYY-MM-DD HH:mm:ss"
    ),
    PromotionStartTimeString: "00:00:00",
    PromotionEndTimeString: "23:59:59",
    IsAreProductsCombined: false,
    ShopName: "",
    IsMonday: true,
    IsTuesday: true,
    IsWednesday: true,
    IsThursday: true,
    IsFriday: true,
    IsSaturday: true,
    IsSunday: true,
    Datetime: "",
    PromotionGroup: "",
    PromotionBuyQTY: 1,
    PromotionShops: shopsArray,
    PromotionProducts: promotionProductsArray,
  });

  useEffect(() => {
    getAllsuppliers();
    getAllShops();

    //console.log("Page Loading..");

    //for edit mode
    if (
      location != null &&
      location.action != null &&
      location.rowData != null
    ) {
      setEditPromotionID(location.rowData.Promotion_ID);
      //console.log("location.rowData.Promotion_ID ",location.rowData.Promotion_ID);
    }
  }, []);

  useEffect(() => {
    if (parseInt(editPromotionID) > 0) {
      getPromotionForEdit(editPromotionID);
    }
  }, [editPromotionID]);

  const getPromotionForEdit = (_id) => {
    setLoading(true);
    axios
      .post(
        Utils.apiBaseURL + "promotions/getbyid",
        { PromotionID: _id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        // console.log("Promotion loaded", response.data.promotion);

        var localPromotionProductsArray = [];
        response.data.promotion.products.map((item, index) => {
          var product = {
            ProductID: item.Product_ID,
            Barcode: item.product_barcode,
            Name: item.product_name,
            Price: item.product_selling_price,
            Group: item.Product_Group,
            QTY: item.Product_Combo_QTY,
          };

          localPromotionProductsArray.push(product);
          return 0;
        });

        var localShopsArray = [];

        response.data.promotion.shops.map((item, index) => {
          var itemShop = {
            ArrayIndex: index,
            ShopID: item.id,
            ShopName: item.shop_name,
            IsChecked: item.status,
          };

          localShopsArray.push(itemShop);
          return 0;
        });

        // console.log("localShopsArray :", localShopsArray);

        var stime = new Date(
          new Date(response.data.promotion.Promotion_Start_Date)
        );

        stime.setHours(
          response.data.promotion.Promotion_Start_Time.split(":")[0]
        );
        stime.setMinutes(
          response.data.promotion.Promotion_Start_Time.split(":")[1]
        );
        stime.setSeconds(
          response.data.promotion.Promotion_Start_Time.split(":")[2]
        );

        var etime = new Date(
          new Date(response.data.promotion.Promotion_End_Date)
        );
        etime.setHours(
          response.data.promotion.Promotion_End_Time.split(":")[0]
        );
        etime.setMinutes(
          response.data.promotion.Promotion_End_Time.split(":")[1]
        );
        etime.setSeconds(
          response.data.promotion.Promotion_End_Time.split(":")[2]
        );

        var localPromotion = {
          Promotion_ID: _id,
          PromotionType: response.data.promotion.Promotion_Type_ID,
          PromotionName: response.data.promotion.Promotion_Name,
          InternalPromotionName: response.data.promotion.Promotion_Name,
          BuyQty: response.data.promotion.Promotion_QTY,
          FreeQty: response.data.promotion.Promotion_Free_QTY,
          PromotionPrice: response.data.promotion.Promotion_Price,
          PromotionDiscount: response.data.promotion.Promotion_Discount,
          IsPromotionBySupplier:
            response.data.promotion.is_Promotion_By_Supplier,
          IsForLoyalCustomerOnly:
            response.data.promotion.is_For_Loyal_Customer_Only,
          SupplierId: response.data.promotion.Supplier_ID,
          ProductBarcode: "",
          PromotionStartDate: new Date(
            response.data.promotion.Promotion_Start_Date.replace(
              "T",
              " "
            ).replace("Z", "")
          ),
          PromotionEndDate: new Date(
            response.data.promotion.Promotion_End_Date.replace(
              "T",
              " "
            ).replace("Z", "")
          ),
          PromotionStartTime: stime,
          PromotionEndTime: etime,

          PromotionStartDateString:
            moment(
              response.data.promotion.Promotion_Start_Date.replace(
                "T",
                " "
              ).replace("Z", "")
            ).format("YYYY-MM-DD") + " 00:00:00",
          PromotionEndDateString:
            moment(
              response.data.promotion.Promotion_End_Date.replace(
                "T",
                " "
              ).replace("Z", "")
            ).format("YYYY-MM-DD") + " 23:59:59",
          PromotionStartTimeString: moment(stime).format("HH:mm:ss"),
          PromotionEndTimeString: moment(etime).format("HH:mm:ss"),

          IsAreProductsCombined: response.data.promotion.are_products_combined,
          ShopName: "",
          IsMonday: response.data.promotion.Monday,
          IsTuesday: response.data.promotion.Tuesday,
          IsWednesday: response.data.promotion.Wednesday,
          IsThursday: response.data.promotion.Thursday,
          IsFriday: response.data.promotion.Friday,
          IsSaturday: response.data.promotion.Saturday,
          IsSunday: response.data.promotion.Sunday,
          Datetime: "",
          PromotionGroup: "",
          PromotionBuyQTY: 1,
          PromotionShops: localShopsArray,
          PromotionProducts: localPromotionProductsArray,
        };

        setPromotionProductsArray(localPromotionProductsArray);
        setShopsArray(localShopsArray);
        //console.log("localPromotion ", localPromotion);
        setFormData(localPromotion);
      })
      .catch((ex) => {
        setLoading(false);
        if (ex.response) {
          console.log(ex.response);
        } else if (ex.request) {
          console.log(ex.request);
        } else {
          console.log("Error", ex.message);
        }
      });
  };

  const handleChange = (e) => {
    //console.log("handleChange called!!");

    if (e.target.name !== "PromotionType" && formData.PromotionType === "") {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please select Promotion Type!!",
        life: 3000,
      });
      return;
    }
    // console.log(e.target.name + ":", e.target.value);
    const inputValue =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: inputValue,
      };
    });

    //promotion type validations
    if (e.target.name === "PromotionType") {
      //any items for x and was x now y
      if (parseInt(e.target.value) === 5 || parseInt(e.target.value) === 3) {
        setFormData((prevalue) => {
          return {
            ...prevalue,
            BuyQty: 1,
          };
        });
      } else {
        setFormData((prevalue) => {
          return {
            ...prevalue,
            BuyQty: "",
          };
        });
      }
    }
  };

  const handlePromotionShopChange = (e, index) => {
    const inputValue =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    let localArray = shopsArray.map((item) => {
      if (item.ArrayIndex === index) {
        item["IsChecked"] = inputValue;
      }

      return item;
    });
    setFormData({
      ...formData,
      PromotionShops: localArray,
    });
    setShopsArray(localArray);
  };

  const handleDeletePromotionProduct = (e, arrayIndex) => {
    // console.log("index to delete ", arrayIndex);
    var localPromotionProductsArray = promotionProductsArray;
    for (let index = 0; index < localPromotionProductsArray.length; index++) {
      // console.log("before delete ", localPromotionProductsArray);
      localPromotionProductsArray.splice(arrayIndex, 1);
      // console.log("after delete ", localPromotionProductsArray);
    }
    setPromotionProductsArray(localPromotionProductsArray.slice(0));
    setFormData({
      ...formData,
      PromotionProducts: localPromotionProductsArray,
    });
  };

  const handleAddProductToPromotion = (e) => {
    if (formData.ProductBarcode === "") {
      alert("Barcode Empty!!");
      return;
    }
    setLoading(true);
    axios
      .post(
        Utils.apiBaseURL + "products/getproductbybarcode",
        {
          product_barcode: formData.ProductBarcode,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        // console.log("promotionProductsArray ", promotionProductsArray);
        var localProductArray = promotionProductsArray;
        var product = {
          ProductID: response.data.product.id,
          Barcode: response.data.product.product_barcode,
          Name: response.data.product.product_name,
          Price: response.data.product.product_selling_price,
          Group: formData.PromotionType === "6" ? formData.PromotionGroup : "",
          QTY: formData.PromotionType === "6" ? formData.PromotionBuyQTY : 1,
        };
        localProductArray.push(product);

        // console.log(localProductArray);
        setPromotionProductsArray(localProductArray);

        setFormData({
          ...formData,
          ProductBarcode: "",
          PromotionGroup: "A",
          PromotionBuyQTY: 1,
          PromotionProducts: localProductArray,
        });
        setLoading(false);
      })
      .catch((ex) => {
        setLoading(false);
        if (ex.response) {
          console.log(ex.response);
        } else if (ex.request) {
          console.log(ex.request);
        } else {
          console.log("Error", ex.message);
        }
      });
  };

  const getAllsuppliers = () => {
    setLoading(true);
    axios
      .post(
        Utils.apiBaseURL + "suppliers/getall",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setsuppliers(response.data.suppliers);
        // console.log("setallsuppliers", response.data.suppliers);
        setLoading(false);
      })
      .catch((ex) => {
        setLoading(false);
        if (ex.response) {
          console.log(ex.response);
        } else if (ex.request) {
          console.log(ex.request);
        } else {
          console.log("Error", ex.message);
        }
      });
  };

  const getAllShops = () => {
    setLoading(true);
    axios
      .post(
        Utils.apiBaseURL + "shops/getall",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        // console.log("Shops array ", response.data.shops);

        var localShopsArray = [];
        response.data.shops.map((item, index) => {
          var itemShop = {
            ArrayIndex: index,
            ShopID: item.id,
            ShopName: item.shop_name,
            IsChecked: true,
          };

          localShopsArray.push(itemShop);
          return 0;
        });

        setShopsArray(localShopsArray);
        setFormData({
          ...formData,
          PromotionShops: localShopsArray,
        });
        setLoading(false);
      })
      .catch((ex) => {
        setLoading(false);
        if (ex.response) {
          console.log(ex.response);
        } else if (ex.request) {
          console.log(ex.request);
        } else {
          console.log("Error", ex.message);
        }
      });
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function formatTime(date) {
    var d = new Date(date),
      hour = "" + d.getHours(),
      minute = "" + d.getMinutes();

    if (hour.length < 2) hour = "0" + hour;
    if (minute.length < 2) minute = "0" + minute;

    return [hour, minute].join(":");
  }
  const handleSubmit = (e) => {
    //console.log(formData);

    if (formData.PromotionProducts.length === 0) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail:
          "No Product(s) added for the Promotion! Add Product(s) to Save Promotions!!",
        life: 3000,
      });
      return;
    }

    // setFormData((prevFormData) => {
    //   const updatedFormData = { ...prevFormData };
    //   // Update PromotionName based on conditions

    //   updatedFormData.PromotionStartDate = "PromotionStartDate"; //formatDate(formData.PromotionStartDate).replaceAll("-", "");
    //   updatedFormData.PromotionEndDate = "PromotionEndDate"; //formatDate(formData.PromotionEndDate).replaceAll("-", "");
    //   updatedFormData.PromotionStartTime = "PromotionStartTime"; //formatTime(formData.PromotionStartTime).replaceAll(":", "");
    //   updatedFormData.PromotionEndTime = "PromotionEndTime"; //formatTime(formData.PromotionEndTime).replaceAll(":", "");

    //   return updatedFormData;
    // });

    // setFormData({
    //   ...formData,
    //   PromotionStartDate: formatDate(formData.PromotionStartDate).replaceAll(
    //     "-",
    //     ""
    //   ),
    //   PromotionEndDate: formatDate(formData.PromotionEndDate).replaceAll(
    //     "-",
    //     ""
    //   ),
    //   PromotionStartTime: formatTime(formData.PromotionStartTime).replaceAll(
    //     ":",
    //     ""
    //   ),
    //   PromotionEndTime: formatTime(formData.PromotionEndTime).replaceAll(
    //     ":",
    //     ""
    //   ),
    // });

    // console.log("Payload after ", formData);

    //return;
    var apiURL = "";
    if (
      location != null &&
      location.action != null &&
      location.action === "edit"
    ) {
      apiURL = Utils.apiBaseURL + "promotions/edit";
    } else {
      apiURL = Utils.apiBaseURL + "promotions/add";
    }

    setLoading(true);
    axios
      .post(
        apiURL,
        {
          formData: formData,
          promotion_id: editPromotionID,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        // console.log("handleSubmit Response ", response);

        if (
          response.data.status != null &&
          response.data.status === "success"
        ) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: response.data.message,
            life: 3000,
          });

          //setShopsArray([]);
          setPromotionProductsArray([]);

          setFormData({
            ...formData,
            PromotionType: "",
            PromotionName: "",
            InternalPromotionName: "",
            BuyQty: "",
            FreeQty: "0",
            PromotionPrice: "0",
            PromotionDiscount: "0",
            IsPromotionBySupplier: false,
            IsForLoyalCustomerOnly: false,
            SupplierId: 0,
            ProductBarcode: "",
            PromotionStartDate: defaultStartDate,
            PromotionEndDate: defaultEndDate,
            PromotionStartTime: defaultStartDate,
            PromotionEndTime: defaultEndDate,
            PromotionStartDateString: moment(defaultStartDate).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            PromotionEndDateString: moment(defaultEndDate).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            PromotionStartTimeString: "00:00:00",
            PromotionEndTimeString: "23:59:59",
            IsAreProductsCombined: false,
            ShopName: "",
            IsMonday: true,
            IsTuesday: true,
            IsWednesday: true,
            IsThursday: true,
            IsFriday: true,
            IsSaturday: true,
            IsSunday: true,
            Datetime: "",
            PromotionGroup: "",
            PromotionBuyQTY: 1,

            PromotionProducts: [],
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: response.data.message,
            life: 3000,
          });
        }
        setLoading(false);
      })
      .catch((ex) => {
        setLoading(false);
        var errordetail;
        if (ex.response) {
          console.log(ex.response);
          errordetail = ex.response;
        } else if (ex.request) {
          console.log(ex.request);
          errordetail = ex.request;
        } else {
          console.log("Error", ex.message);
          errordetail = ex.messag;
        }
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errordetail,
          life: 10000,
        });
      });
  };

  const updatePromotionName = () => {
    // console.log("updatePromotionName called!!");
    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData };
      // Update PromotionName based on conditions

      //Buy N get N Free
      if (parseInt(updatedFormData.PromotionType) === 1) {
        // console.log("Buy N get N Free");
        // console.log(updatedFormData.BuyQty, updatedFormData.FreeQty);
        if (
          parseInt(updatedFormData.BuyQty) > 0 &&
          parseInt(updatedFormData.FreeQty) > 0
        ) {
          updatedFormData.PromotionName = `Buy ${updatedFormData.BuyQty} get ${updatedFormData.FreeQty} free`;
          updatedFormData.InternalPromotionName = `Buy ${updatedFormData.BuyQty} get ${updatedFormData.FreeQty} free`;

          console.log(updatedFormData.PromotionName);
        }
      }

      //Buy N for XX amount
      if (parseInt(updatedFormData.PromotionType) === 2) {
        // console.log("Buy N for XX amount");
        if (
          parseInt(updatedFormData.BuyQty) > 0 &&
          parseInt(updatedFormData.PromotionPrice) > 0
        ) {
          updatedFormData.PromotionName = `Buy ${updatedFormData.BuyQty} for ${updatedFormData.PromotionPrice}`;
          updatedFormData.InternalPromotionName = `Buy ${updatedFormData.BuyQty} for ${updatedFormData.PromotionPrice}`;
        }

        if (
          parseInt(updatedFormData.BuyQty) > 0 &&
          parseInt(updatedFormData.PromotionDiscount) > 0
        ) {
          updatedFormData.PromotionName = `Buy ${updatedFormData.BuyQty} get ${updatedFormData.PromotionDiscount}% off`;
          updatedFormData.InternalPromotionName = `Buy ${updatedFormData.BuyQty} get ${updatedFormData.PromotionDiscount}% off`;
        }
      }

      // "Was x now XX amount");
      if (parseInt(updatedFormData.PromotionType) === 3) {
        // console.log("Buy N for XX amount");
        if (parseFloat(updatedFormData.PromotionPrice) > 0) {
          updatedFormData.PromotionName = `Now ${updatedFormData.PromotionPrice} only`;
          updatedFormData.InternalPromotionName = `Now ${updatedFormData.PromotionPrice} only`;
        }

        if (
          parseInt(updatedFormData.BuyQty) > 0 &&
          parseFloat(updatedFormData.PromotionDiscount) > 0
        ) {
          updatedFormData.PromotionName = `Now ${updatedFormData.PromotionDiscount}% off`;
          updatedFormData.InternalPromotionName = `Now ${updatedFormData.PromotionDiscount}% off`;
        }

        updatedFormData.FreeQty = 0;
      }

      if (parseInt(updatedFormData.PromotionType) === 5) {
        // console.log("Any Items for x");
        if (parseInt(updatedFormData.PromotionPrice) > 0) {
          updatedFormData.PromotionName = `Any Item for ${updatedFormData.PromotionPrice} only`;
          updatedFormData.InternalPromotionName = `Any Item for ${updatedFormData.PromotionPrice} only`;
        }

        if (
          parseInt(updatedFormData.BuyQty) > 0 &&
          parseInt(updatedFormData.PromotionDiscount) > 0
        ) {
          updatedFormData.PromotionName = `Any Item for ${updatedFormData.PromotionDiscount}% off`;
          updatedFormData.InternalPromotionName = `Any Item for ${updatedFormData.PromotionDiscount}% off`;
        }
      }

      return updatedFormData;
    });
  };

  return (
    <>
      <Toast ref={toast} />
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1> Promotion Information</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">Add Promotion</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Promotion Information</h3>
                </div>

                <form>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Promotion Type*</label>
                          <select
                            className="form-control"
                            name="PromotionType"
                            value={formData.PromotionType}
                            onChange={(e) => {
                              handleChange(e);
                              updatePromotionName();
                            }}
                            required="true"
                          >
                            <option value="">--SELECT--</option>
                            <option value="1">Buy N get N Free</option>
                            <option value="2">Buy N for X.XX amount</option>
                            <option value="3">Was X.XX Now X.XX</option>
                            <option value="4">
                              Bulk Buy Discount/Step Promotion
                            </option>
                            <option value="5">Any Items for X.XX</option>
                            <option value="6">Combo/Meal Deal Promotion</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Promotion Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="PromotionName"
                            value={formData.PromotionName}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            required="true"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Internal Promotion Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="InternalPromotionName"
                            value={formData.InternalPromotionName}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Buy Qty</label>
                          <input
                            type="text"
                            className="form-control"
                            name="BuyQty"
                            value={formData.BuyQty}
                            onChange={handleChange}
                            disabled={
                              parseInt(formData.PromotionType) === 5 ||
                              parseInt(formData.PromotionType) === 3 ||
                              parseInt(formData.PromotionType) === 6
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Free Qty</label>
                          <input
                            type="text"
                            className="form-control"
                            name="FreeQty"
                            value={formData.FreeQty}
                            onChange={(e) => {
                              handleChange(e);
                              updatePromotionName();
                            }}
                            disabled={parseInt(formData.PromotionType) !== 1}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Promotion Price</label>
                          <input
                            type="text"
                            className="form-control"
                            name="PromotionPrice"
                            value={formData.PromotionPrice}
                            onChange={(e) => {
                              handleChange(e);
                              updatePromotionName();
                            }}
                            disabled={
                              parseInt(formData.PromotionType) === 4 ||
                              parseInt(formData.PromotionType) === 1 ||
                              parseFloat(formData.PromotionDiscount) > 0
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Promotion Discount</label>
                          <input
                            type="number"
                            className="form-control"
                            name="PromotionDiscount"
                            value={formData.PromotionDiscount}
                            onChange={(e) => {
                              handleChange(e);
                              updatePromotionName();
                            }}
                            disabled={
                              parseInt(formData.PromotionType) === 4 ||
                              parseInt(formData.PromotionType) === 1 ||
                              parseFloat(formData.PromotionPrice) > 0
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <div className="custom-control custom-checkbox">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                              id="IsPromotionBySupplier"
                              name="IsPromotionBySupplier"
                              value={formData.IsPromotionBySupplier}
                              checked={formData.IsPromotionBySupplier}
                              defaultChecked={formData.IsPromotionBySupplier}
                              onChange={handleChange}
                            />
                            <label
                              for="IsPromotionBySupplier"
                              className="custom-control-label"
                            >
                              Is Promotion By Supplier
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <div className="custom-control custom-checkbox">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                              id="IsForLoyalCustomerOnly"
                              name="IsForLoyalCustomerOnly"
                              value={formData.IsForLoyalCustomerOnly}
                              checked={formData.IsForLoyalCustomerOnly}
                              defaultChecked={formData.IsForLoyalCustomerOnly}
                              onChange={handleChange}
                            />
                            <label
                              for="IsForLoyalCustomerOnly"
                              className="custom-control-label"
                            >
                              Is For Loyal Customer Only
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Supplier ID</label>
                      <select
                        className="form-control"
                        name="supplier_name"
                        value={formData.supplier_name}
                        onChange={handleChange}
                      >
                        <option value={""}>--SELECT--</option>
                        {suppliers.map((sup, index) => {
                          return (
                            <option key={index}>{sup.supplier_name}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </form>
              </div>

              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Promotion Products</h3>
                </div>

                <div className="card-body">
                  <div className="row">
                    {formData.PromotionType === "6" && (
                      <>
                        <div className="col-sm-2">
                          <label for="exampleInputRounded0">Group</label>
                          <select
                            className="form-control"
                            name="PromotionGroup"
                            value={formData.PromotionGroup}
                            onChange={handleChange}
                          >
                            <option>A</option>
                            <option>B</option>
                            <option>C</option>
                            <option>D</option>
                            <option>E</option>
                            <option>F</option>
                            <option>G</option>
                            <option>H</option>
                          </select>
                        </div>
                        <div className="col-sm-2">
                          <label for="exampleInputRounded0">Buy QTY</label>
                          <input
                            type="number"
                            className="form-control"
                            name="PromotionBuyQTY"
                            value={formData.PromotionBuyQTY}
                            onChange={handleChange}
                          />
                        </div>
                      </>
                    )}
                    <div className="col-4">
                      <label for="exampleInputRounded0">Product Barcode</label>
                      <input
                        type="text"
                        className="form-control"
                        name="ProductBarcode"
                        value={formData.ProductBarcode}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-4 mt-2">
                      <div className="custom-control custom-checkbox">
                        <input
                          className="custom-control-input"
                          type="checkbox"
                          id="IsAreProductsCombined"
                          name="IsAreProductsCombined"
                          value={formData.IsAreProductsCombined}
                          checked={formData.IsAreProductsCombined}
                          defaultChecked={formData.IsAreProductsCombined}
                          onChange={handleChange}
                        />
                        <label
                          for="IsAreProductsCombined"
                          className="custom-control-label"
                        >
                          Are Products Combined
                        </label>
                      </div>
                      <button
                        className="btn btn-primary btn-block"
                        value={"Add Product"}
                        onClick={handleAddProductToPromotion}
                      >
                        Add
                      </button>
                    </div>
                  </div>

                  <table className="table bordered">
                    {promotionProductsArray.length > 0 && (
                      <thead>
                        <tr>
                          <th>Barcode</th>
                          <th>Name</th>
                          <th>Price</th>
                          <th>Group</th>
                          <th>QTY</th>
                          <th></th>
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      {promotionProductsArray?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.Barcode}</td>
                            <td>{item.Name}</td>
                            <td>{item.Price}</td>
                            <td>{item.Group}</td>
                            <td>{item.QTY}</td>
                            <td>
                              <button
                                className="btn btn-danger btn-sm"
                                value={"Delete"}
                                onClick={(e) =>
                                  handleDeletePromotionProduct(e, index)
                                }
                              >
                                X
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Promotion Period</h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label for="exampleInputRounded0">
                          Promotion Start
                        </label>

                        <div className="input-group">
                          <Calendar
                            id="PromotionStartDate"
                            name="PromotionStartDate"
                            value={formData.PromotionStartDate}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                PromotionStartDate: e.target.value,
                                PromotionStartDateString:
                                  moment(e.target.value).format("YYYY-MM-DD") +
                                  " 00:00:00",
                              });
                            }}
                            showIcon
                            dateFormat="dd/mm/yy"
                            disabled={formData.PromotionType.length === 0}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label for="PromotionEndDate">Promotion End</label>
                        <div className="input-group">
                          <Calendar
                            id="PromotionEndDate"
                            name="PromotionEndDate"
                            value={formData.PromotionEndDate}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                PromotionEndDate: e.target.value,
                                PromotionEndDateString:
                                  moment(e.target.value).format("YYYY-MM-DD") +
                                  " 23:59:59",
                              });
                            }}
                            showIcon
                            dateFormat="dd/mm/yy"
                            disabled={formData.PromotionType.length === 0}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 pt-3">
                      <button
                        type="submit"
                        className="btn btn-info btn-lg btn-block mt-3"
                        onClick={() => {
                          var d = new Date();
                          d.setHours(0);
                          d.setMinutes(0);
                          d.setSeconds(0);
                          setFormData({
                            ...formData,
                            PromotionStartDate: d,
                            PromotionStartDateString: moment(d).format(
                              "YYYY-MM-DD HH:mm:ss"
                            ),
                            PromotionEndDate: new Date("2999-12-31 23:59:59"),
                            PromotionEndDateString: moment(
                              new Date("2999-12-31 23:59:59")
                            ).format("YYYY-MM-DD HH:mm:ss"),
                          });
                        }}
                        disabled={formData.PromotionType.length === 0}
                      >
                        Life Time Promotion
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label for="PromotionStartTime">
                          Promotion Start Time
                        </label>
                        <div className="input-group">
                          <Calendar
                            id="PromotionStartTime"
                            name="PromotionStartTime"
                            value={formData.PromotionStartTime}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                PromotionStartTime: e.target.value,
                                PromotionStartTimeString: moment(
                                  e.target.value
                                ).format("HH:mm:ss"),
                              });
                            }}
                            showIcon
                            timeOnly
                            hourFormat="24"
                            disabled={formData.PromotionType.length === 0}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label for="PromotionEndTime">Promotion End Time</label>
                        <div className="input-group">
                          <Calendar
                            id="PromotionEndTime"
                            name="PromotionEndTime"
                            value={formData.PromotionEndTime}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                PromotionEndTime: e.target.value,
                                PromotionEndTimeString: moment(
                                  e.target.value
                                ).format("HH:mm:ss"),
                              });
                            }}
                            showIcon
                            timeOnly
                            hourFormat="24"
                            disabled={formData.PromotionType.length === 0}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 pt-3">
                      <button
                        type="submit"
                        className="btn btn-info btn-lg btn-block mt-3"
                        onClick={() => {
                          var s = new Date();
                          s.setHours(0);
                          s.setMinutes(0);
                          s.setSeconds(0);
                          var e = new Date();
                          e.setHours(23);
                          e.setMinutes(59);
                          e.setSeconds(59);
                          setFormData({
                            ...formData,
                            PromotionStartTime: s,
                            PromotionEndTime: e,
                            PromotionStartTimeString:
                              moment(s).format("HH:mm:ss"),
                            PromotionEndTimeString:
                              moment(e).format("HH:mm:ss"),
                          });
                        }}
                        disabled={formData.PromotionType.length === 0}
                      >
                        24 Hour Promotion
                      </button>
                    </div>
                  </div>
                  <h5>Day</h5>
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="form-group">
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="IsMonday"
                            name="IsMonday"
                            value={formData.IsMonday}
                            checked={formData.IsMonday}
                            defaultValue={formData.IsMonday}
                            onChange={handleChange}
                          />
                          <label
                            for="IsMonday"
                            className="custom-control-label"
                          >
                            Monday
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="IsTuesday"
                            name="IsTuesday"
                            value={formData.IsTuesday}
                            checked={formData.IsTuesday}
                            defaultValue={formData.IsTuesday}
                            onChange={handleChange}
                            defaultChecked="true"
                          />
                          <label
                            for="IsTuesday"
                            className="custom-control-label"
                          >
                            Tuesday
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="IsWednesday"
                            name="IsWednesday"
                            value={formData.IsWednesday}
                            checked={formData.IsWednesday}
                            defaultValue={formData.IsWednesday}
                            onChange={handleChange}
                          />
                          <label
                            for="IsWednesday"
                            className="custom-control-label"
                          >
                            Wednesday
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="IsThursday"
                            name="IsThursday"
                            value={formData.IsThursday}
                            checked={formData.IsThursday}
                            defaultChecked={formData.IsThursday}
                            onChange={handleChange}
                          />
                          <label
                            for="IsThursday"
                            className="custom-control-label"
                          >
                            Thursday
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="IsFriday"
                            name="IsFriday"
                            value={formData.IsFriday}
                            checked={formData.IsFriday}
                            defaultChecked={formData.IsFriday}
                            onChange={handleChange}
                          />
                          <label
                            for="IsFriday"
                            className="custom-control-label"
                          >
                            Friday
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="IsSaturday"
                            name="IsSaturday"
                            value={formData.IsSaturday}
                            checked={formData.IsSaturday}
                            defaultChecked={formData.IsSaturday}
                            onChange={handleChange}
                          />
                          <label
                            for="IsSaturday"
                            className="custom-control-label"
                          >
                            Saturday
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="IsSunday"
                            name="IsSunday"
                            value={formData.IsSunday}
                            checked={formData.IsSunday}
                            defaultChecked={formData.IsSunday}
                            onChange={handleChange}
                          />
                          <label
                            for="IsSunday"
                            className="custom-control-label"
                          >
                            Sunday
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Promotion Shops</h3>
                </div>

                <div className="card-body p-0">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Shop Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {shopsArray?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <div className="form-group">
                                <div className="custom-control custom-checkbox">
                                  <input
                                    className="custom-control-input"
                                    type="checkbox"
                                    id={"customCheckbox" + item.ShopID}
                                    value={item.IsChecked}
                                    defaultChecked={item.IsChecked}
                                    checked={item.IsChecked}
                                    onChange={(e) =>
                                      handlePromotionShopChange(e, index)
                                    }
                                  />
                                  <label
                                    for={"customCheckbox" + item.ShopID}
                                    className="custom-control-label"
                                  >
                                    {item.ShopName}
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content-footer mt-4">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-12">
              <input
                type="Submit"
                name="btnSubmit"
                value="Submit"
                className="btn btn-primary float-right mr-3"
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
