import axios from "axios";
import React from "react";
import { useHistory } from "react-router";
import Utils from "../Utils";

export default function SignIn() {
  const apiURL = Utils.apiBaseURL + "users/login";
  const history = useHistory();
  const [error, setError] = React.useState("");

  const checkLogin = (e) => {
    e.preventDefault();
    axios
      .post(apiURL, {
        email: e.target.elements.email.value,
        password: e.target.elements.password.value,
      })
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("auth", true);
        localStorage.setItem("name", response.data.user.name);
        localStorage.setItem("email", e.target.elements.email.value);
        window.location = "/";
      })

      .catch((ex) => {
        if (ex.response) {
          setError(ex.response.data.msg);
        } else if (ex.request) {
          setError(ex.message);
        } else {
          setError(ex.message);
        }
      });
  };
  return (
    <div className="login-box">
      <div className="card card-outline card-primary">
        <div className="card-header text-center">
          <div className="h1">
            <b>Head Office</b>
          </div>
        </div>
        <div className="card-body">
          <p className="login-box-msg">Sign in to manage your store</p>

          <form method="post" onSubmit={checkLogin}>
            <div className="input-group mb-3">
              <input
                type="email"
                className="form-control"
                name="email"
                placeholder="Email"
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-envelope-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="input-group mb-3">
              <input
                type="password"
                name="password"
                className="form-control"
                placeholder="Password"
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-lock-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <button type="submit" className="btn btn-primary btn-block">
                  Sign In
                </button>
              </div>
              <p>{error}</p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
