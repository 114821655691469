import React, { Component } from "react";

import "./scss/adminlte.scss";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import Dashboard from "./pages/dashboard/index";

import Products from "./pages/products/index";
import PoductsGrouped from "./pages/products-grouped/index";
import AddEditProducts from "./pages/products/add-edit-products";
import Promotions from "./pages/promotions/index";
import AddEditPromotion from "./pages/promotions/add-edit-promotions";
import promotionsproducts from "./pages/promotions-products/index";
import Addpromotionsproducts from "./pages/promotions-products/add-promotions-products";
import UnverifiedProducts from "./pages/unverified-products/index";
import Departments from "./pages/departments/index";
import AddDepartments from "./pages/departments/add-department";
import EditDepartments from "./pages/departments/edit-department";
import AddEditDepartment from "./pages/departments/add-edit-department";
import SubDepartments from "./pages/sub-departments/index";
import AddSubDepartments from "./pages/sub-departments/add-subdepartment";
import EditSubDepartments from "./pages/sub-departments/edit-subdepartment";
import Settings from "./pages/settings/index";
import Supplier from "./pages/suppliers/index";
import AddSupplier from "./pages/suppliers/add-supplier";
import Vat from "./pages/vat/index";
import AddVat from "./pages/vat/add-vat";
import SignIn from "./pages/signin";
import Main from "./layouts/Main";
import AuthLayout from "./layouts/AuthLayout";
import ChangePassword from "./pages/changepassword";

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showTopMenuBar: true,
      showLeftMenuBar: false,
    };

    this.toggleLeftMenu = this.toggleLeftMenu.bind(this);
  }

  toggleLeftMenu() {
    this.setState({ showLeftMenuBar: !this.state.showLeftMenuBar });
  }

  render() {
    const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
      <Route
        {...rest}
        render={(props) => {
          if (
            localStorage.getItem("auth") === "true" ||
            props.location.pathname === "/signin"
          ) {
            return (
              <Layout>
                <Component {...props}></Component>
              </Layout>
            );
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/signin",
                }}
              />
            );
          }
        }}
      ></Route>
    );

    return (
      <Router>
        <Switch>
          <AppRoute exact path="/" component={Dashboard} layout={Main} />
          <AppRoute path="/signin" component={SignIn} layout={AuthLayout} />
          <AppRoute path="/products" component={Products} layout={Main} />
          {/* <AppRoute
            path="/add-products"
            component={AddProducts}
            layout={Main}
          /> */}
          {/* <AppRoute
            path="/edit-products"
            component={EditProducts}
            layout={Main}
          /> */}
          <AppRoute
            path="/add-edit-products"
            component={AddEditProducts}
            layout={Main}
          />
          <AppRoute
            path="/unverified-products"
            component={UnverifiedProducts}
            layout={Main}
          />
          <AppRoute
            path="/products-grouped"
            component={PoductsGrouped}
            layout={Main}
          />
          <AppRoute path="/departments" component={Departments} layout={Main} />
          <AppRoute
            path="/add-department"
            component={AddDepartments}
            layout={Main}
          />
          <AppRoute
            path="/Edit-department"
            component={EditDepartments}
            layout={Main}
          />
          <AppRoute
            path="/add-edit-department"
            component={AddEditDepartment}
            layout={Main}
          />
          <AppRoute
            path="/sub-departments"
            component={SubDepartments}
            layout={Main}
          />
          <AppRoute
            path="/add-subdepartment"
            component={AddSubDepartments}
            layout={Main}
          />
          <AppRoute
            path="/edit-subdepartment"
            component={EditSubDepartments}
            layout={Main}
          />
          <AppRoute path="/suppliers" component={Supplier} layout={Main} />
          <AppRoute
            path="/add-supplier"
            component={AddSupplier}
            layout={Main}
          />
          <AppRoute path="/promotions" component={Promotions} layout={Main} />
          <AppRoute
            path="/add-edit-promotions"
            component={AddEditPromotion}
            layout={Main}
          />
          <AppRoute
            path="/promotions-products"
            component={promotionsproducts}
            layout={Main}
          />
          <AppRoute
            path="/add-promotions-products"
            component={Addpromotionsproducts}
            layout={Main}
          />
          <AppRoute path="/settings" component={Settings} layout={Main} />
          <AppRoute
            path="/ChangePassword"
            component={ChangePassword}
            layout={Main}
          />
          <AppRoute path="/vat" component={Vat} layout={Main} />
          <AppRoute path="/add-vat" component={AddVat} layout={Main} />
        </Switch>
      </Router>
    );
  }
}
