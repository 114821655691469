import React, { Component } from "react";

const $ = require("jquery");
$.DataTable = require("datatables.net");

class DataTable extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.$el = $(this.el);
    if (table != null) {
      table.destroy();
    }
    var table = this.$el.DataTable({
      data: this.props.data,
      columns: this.props.columns,
      ordering: false,
    });
  }
  componentDidUpdate() {}

  render() {
    return (
      <div className="card-body">
        <table
          id="dataTable"
          width="100%"
          cellSpacing="0"
          className="table table-bordered table-hover"
          ref={(el) => (this.el = el)}
        />
      </div>
    );
  }
}
export default DataTable;
