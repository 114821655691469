import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";

import React, { useState, useEffect } from "react";
import { PrimeIcons } from "primereact/api";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import axios from "axios";
import Utils from "../../Utils";

export default function SubDepartments() {
  const [error, setError] = React.useState();
  const [subdepartments, setSubDepartment] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageInputTooltip, setPageInputTooltip] = React.useState(
    "Press 'Enter' key to go to this page."
  );
  const [loading, setLoading] = useState(false);

  let loadLazyTimeout = null;

  useEffect(() => {
    loadLazyData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadLazyData = () => {
    setLoading(true);

    axios
      .post(
        Utils.apiBaseURL + "subdepartments/getall",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setSubDepartment(response.data.sub_departments);
        setLoading(false);
      })
      .catch((ex) => {
        setLoading(false);
        if (ex.response) {
          console.log(ex.response);
        } else if (ex.request) {
          console.log(ex.request);
        } else {
          console.log("Error", ex.message);
        }
      });
  };
  const discountBodyTemplate = (rowData) => {
    return (
      <span
        className={
          rowData.allow_discount_department === 0
            ? PrimeIcons.TIMES
            : PrimeIcons.CHECK
        }
      />
    );
  };
  const staffBodyTemplate = (rowData) => {
    return (
      <span
        className={
          rowData.allow_staff_discount === 0
            ? PrimeIcons.TIMES
            : PrimeIcons.CHECK
        }
      />
    );
  };
  const vatexlBodyTemplate = (rowData) => {
    return (
      <span
        className={
          rowData.separate_sales_in_report === 0
            ? PrimeIcons.TIMES + " bg-danger "
            : PrimeIcons.CHECK + " bg-success "
        }
      />
    );
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <Link
          className="btn btn-primary"
          to={{
            pathname: "/edit-subdepartment",
            rowData: rowData,
            action: "edit",
          }}
        >
          Edit
        </Link>
      </>
    );
  };

  return (
    <>
      <section className="content pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title font-weight-bold">
                    Sub Departments
                  </h3>
                  <Link to="/add-subdepartment">
                    <button
                      type="button"
                      className="btn btn-primary float-right"
                    >
                      Add Sub Department
                    </button>
                  </Link>
                </div>

                <div className="card-body  text-center">
                  <DataTable
                    value={subdepartments}
                    rowGroupMode="rowspan"
                    groupRowsBy="department_name"
                    showGridlines
                    size="small"
                    responsiveLayout="scroll"
                    paginator
                    loading={loading}
                    filterDisplay="row"
                    rows={50}
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    paginatorTemplate="CurrentPageReport  RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
                    rowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                  >
                    <Column field="id" header="ID" sortable filter />
                    <Column
                      field="department_name"
                      header="Department Name"
                      sortable
                      filter
                    />

                    <Column
                      field="sub_department_name"
                      header="Sub Department name"
                      sortable
                      filter
                    />
                    <Column
                      field="age_limit"
                      header="Age Limit"
                      sortable
                      filter
                    />

                    <Column
                      headerStyle={{ width: "4rem", textAlign: "center" }}
                      bodyStyle={{ textAlign: "center", overflow: "visible" }}
                      body={actionBodyTemplate}
                    />
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
