import axios from "axios";
import React, { useRef } from "react";
import { useHistory } from "react-router";
import Utils from "../Utils";
import { Link } from "react-router-dom";
import { Toast } from "primereact/toast";

export default function ChangePassword() {
  const apiURL = Utils.apiBaseURL + "users/changepassword";
  const history = useHistory();
  const [error, setError] = React.useState("");
  const toast = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(e.target.elements.oldpassword.value);
    console.log(e.target.elements.newpassword.value);
    console.log(e.target.elements.retypepassword.value);

    if (
      e.target.elements.newpassword.value !==
      e.target.elements.retypepassword.value
    ) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "New Password and Re-type New Passwords are not same.!!",
        life: 3000,
      });
      return;
    }

    axios
      .post(apiURL, {
        email: localStorage.getItem("email"),
        oldpassword: e.target.elements.oldpassword.value,
        newpassword: e.target.elements.newpassword.value,
      })
      .then((response) => {
        console.log(response);
        if (response.data.msg === "Password Changed!") {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Password Changed!!",
            life: 3000,
          });

          e.target.reset();
        }
      })
      .catch((ex) => {
        if (ex.response) {
          setError(ex.response.data.msg);
        } else if (ex.request) {
          setError(ex.message);
        } else {
          setError(ex.message);
        }
      });
  };
  return (
    <>
      <Toast ref={toast} />
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Change Password</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">Change Password</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-body">
                  <form method="post" onSubmit={handleSubmit}>
                    <div className="input-group mb-3">
                      <input
                        type="password"
                        name="oldpassword"
                        className="form-control"
                        placeholder="Old Password"
                        required
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-lock-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="input-group mb-3">
                      <input
                        type="password"
                        name="newpassword"
                        className="form-control"
                        placeholder="New Password"
                        required
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-lock-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="input-group mb-3">
                      <input
                        type="password"
                        name="retypepassword"
                        className="form-control"
                        placeholder="Re-type New Password"
                        required
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-lock-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn btn-primary float-right"
                        >
                          Change Password
                        </button>
                      </div>
                      <p>{error}</p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
