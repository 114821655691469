let liveURL = "https://api.epos.run/api/"; //"http://192.168.2.25:3000/api/";
//let liveURL = "http://192.168.2.25:3000/api/";

export default {
  apiBaseURL: liveURL,
  jsonHeader: {
    headers: {
      "Content-type": "application/json",
    },
  },
  multipartFormHeader: {
    headers: {
      "Content-type": "multipart/form-data",
    },
  },
};
