import { React, useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import Utils from "../../Utils";

export default function AddSupplier() {
  const [formData, setFormData] = useState({
    Suppliercode: "",
    SupplierName: "",
    SupplierDescription: "",
    SupplierBalanceDue: "",
    SupplierCreditLimit: "",
    SupplierAddress: "",
    SupplierPhone: "",
    SupplierMobile: "",
    SupplierFax: "",
    SupplierEmail: "",
    SupplierWebsite: "",
  });

  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const inputValue =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: inputValue,
      };
    });
  };

  const getallDepartments = () => {
    setLoading(true);
    axios
      .post(
        Utils.apiBaseURL + "suppliers/getall",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setDepartments(response.data.departments);
        // console.log("getalldept", response.data.departments);
        setLoading(false);
      })
      .catch((ex) => {
        setLoading(false);
        if (ex.response) {
          console.log(ex.response);
        } else if (ex.request) {
          console.log(ex.request);
        } else {
          console.log("Error", ex.message);
        }
      });
  };

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const handleSubmit = (e) => {
    console.log(formData);
  };

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1> Supplier Information</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">Add Supplier</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Supplier Information</h3>
                </div>

                <form>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Supplier Code</label>
                          <input
                            className="form-control"
                            name="Suppliercode"
                            value={formData.Suppliercode}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Supplier Name</label>
                          <input
                            className="form-control"
                            name="SupplierName"
                            value={formData.SupplierName}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Supplier Description</label>
                      <textarea
                        className="form-control"
                        rows="3"
                        name="SupplierDescription"
                        value={formData.SupplierDescription}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <h5>
                      <b>
                        <u>Balance Information</u>
                      </b>
                    </h5>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputRounded0">
                            Supplier Balance Due
                          </label>
                          <input
                            className="form-control rounded-0"
                            name="SupplierBalanceDue"
                            value={formData.SupplierBalanceDue}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputRounded0">
                            Supplier Credit Limit
                          </label>
                          <input
                            type="text"
                            className="form-control rounded-0"
                            name="SupplierCreditLimit"
                            value={formData.SupplierCreditLimit}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Contact Information</h3>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label>Supplier Address</label>
                    <textarea
                      className="form-control"
                      rows="3"
                      name="SupplierAddress"
                      value={formData.SupplierAddress}
                      onChange={handleChange}
                    ></textarea>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="exampleInputRounded0">
                          Supplier Phone
                        </label>
                        <input
                          type="text"
                          className="form-control rounded-0"
                          name="SupplierPhone"
                          value={formData.SupplierPhone}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="exampleInputRounded0">
                          Supplier Mobile
                        </label>
                        <input
                          type="text"
                          className="form-control rounded-0"
                          name="SupplierMobile"
                          value={formData.SupplierMobile}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="exampleInputRounded0">
                          Supplier Fax
                        </label>
                        <input
                          type="text"
                          className="form-control rounded-0"
                          name="SupplierFax"
                          value={formData.SupplierFax}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>Supplier Email</label>
                        <input
                          type="text"
                          className="form-control"
                          name="SupplierEmail"
                          value={formData.SupplierEmail}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>Supplier Website</label>
                        <input
                          type="text"
                          className="form-control"
                          name="SupplierWebsite"
                          value={formData.SupplierWebsite}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="content-footer py-2">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-12">
              <input
                type="button"
                name="btnSubmit"
                value="Submit"
                className="btn btn-primary float-right mr-3"
              />
              <input
                type="button"
                name="btnCancel"
                value="Cancel"
                className="btn btn-success float-right mr-3"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
