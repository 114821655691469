import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "@inovua/reactdatagrid-community/index.css";
import React, { useState, useEffect, useRef } from "react";
import { Column } from "primereact/column";

import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import axios from "axios";
import Utils from "../../Utils";

export default function Products() {
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);

  const [productgroups, setProductgroups] = React.useState();
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 100,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: {
      group_name: { value: "", matchMode: "contains" },

      id: { value: "", matchMode: "contains" },
    },
  });
  const [totalRecords, setTotalRecords] = useState(0);

  

  const [formData, setFormData] = useState({
    GroupName: "",
  });

  useEffect(() => {
    getGroups();
  }, [lazyParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const onPage = (event) => {
    console.log(event);
    setLazyParams(event);
  };
  const onFilter = (event) => {
    event["first"] = 0;
    setLazyParams(event);
  };
  const onSort = (event) => {
    setLazyParams(event);
  };

  const getGroups = () => {
    setLoading(true);
    axios
      .post(
        Utils.apiBaseURL + "products/getproductgroups",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setProductgroups(response.data.groups);
        setTotalRecords(response.data.groups.length);
        setLoading(false);
      })
      .catch((ex) => {
        setLoading(false);
        if (ex.response) {
          console.log(ex.response);
        } else if (ex.request) {
          console.log(ex.request);
        } else {
          console.log("Error", ex.message);
        }
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    var apiURL = "";
    apiURL = Utils.apiBaseURL + "products/addproductgroup";

    setLoading(true);

    axios
      .post(
        apiURL,
        { formData: formData },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        //console.log("API response", response);
        if (
          response.data.status != null &&
          response.data.status === "success"
        ) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: response.data.msg,
            life: 3000,
          });
          setFormData({
            ...formData,
            GroupName: "",
          });
          getGroups();
          setLoading(false);
        } else {
          alert(response.data.error);
        }
      })
      .catch((ex) => {
        setLoading(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: ex.message,
          life: 3000,
        });
      });
  };
  return (
    <>
      <Toast ref={toast} />
      <form onSubmit={handleSubmit}>
        <section className="content mt-2">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Add Group</h3>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label htmlFor="exampleInputRounded0">Group *</label>
                          <input
                            type="text"
                            className="form-control form-control-sm rounded-0"
                            name="group"
                            value={formData.GroupName}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                GroupName: e.target.value,
                              });
                              console.log(e.target.value);
                            }}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <input
                          type="submit"
                          name="btnSubmit"
                          value="Submit"
                          className="btn btn-primary float-right mr-3"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>

      <section className="content pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title font-weight-bold">
                    Products Group
                  </h3>
                </div>

                <div className="card-body">
                  <DataTable
                    value={productgroups}
                    showGridlines
                    size="small"
                    responsiveLayout="scroll"
                    paginator
                    lazy
                    first={lazyParams.first}
                    rows={lazyParams.rows}
                    totalRecords={totalRecords}
                    onPage={onPage}
                    onFilter={onFilter}
                    filters={lazyParams.filters}
                    loading={loading}
                    /*  filterDisplay="row" */
                    onSort={onSort}
                    sortField={lazyParams.sortField}
                    sortOrder={lazyParams.sortOrder}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    rowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                    editMode="cell"
                  >
                    <Column field="id" header="ID" sortable filter />
                    <Column field="group_name" header="Group" sortable filter />
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
