import { React, useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import Utils from "../../Utils";

export default function EditSubDepartment() {
  const location = useLocation();

  const [departmentsArray, setDepartmentsArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editSubDepartmentID, setEditSubDepartmentID] = useState(0);
  const [formData, setFormData] = useState({
    DepartmentID: 0,
    SubDepartmentName: "",
    AgeLimit: 99,
  });

  const handleChange = (e) => {
    console.log(e.target.name + ":", e.target.value);
    const inputValue =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: inputValue,
      };
    });
  };

  useEffect(() => {
    setalldepartments();
    if (
      location != null &&
      location.action != null &&
      location.rowData != null
    ) {
      setEditSubDepartmentID(location.rowData.id);
    }
  }, []);

  useEffect(() => {
    if (editSubDepartmentID > 0) {
      console.log("editSubDepartmentID ", editSubDepartmentID);
      getSubDepartmentForEdit(editSubDepartmentID);
    }
  }, [editSubDepartmentID]);

  const getSubDepartmentForEdit = (_id) => {
    console.log(_id);

    setLoading(true);
    axios
      .post(
        Utils.apiBaseURL + "subdepartments/getbyid",
        { subdepartment_id: _id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        var dept = {
          DepartmentID: response.data.sub_department.department_id,
          SubDepartmentName: response.data.sub_department.sub_department_name,
          AgeLimit: response.data.sub_department.age_limit,
        };
        console.log(dept);
        setFormData(dept);
      })
      .catch((ex) => {
        setLoading(false);
        if (ex.response) {
          console.log(ex.response);
        } else if (ex.request) {
          console.log(ex.request);
        } else {
          console.log("Error", ex.message);
        }
      });
  };

  const setalldepartments = () => {
    setLoading(true);
    axios
      .post(
        Utils.apiBaseURL + "departments/getall",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setDepartmentsArray(response.data.departments);
        setLoading(false);
      })
      .catch((ex) => {
        setLoading(false);
        if (ex.response) {
          console.log(ex.response);
        } else if (ex.request) {
          console.log(ex.request);
        } else {
          console.log("Error", ex.message);
        }
      });
  };

  const handleSubmit = (e) => {
    console.log(formData);
    e.preventDefault();
    setLoading(true);

    axios
      .post(
        Utils.apiBaseURL + "subdepartments/edit",
        { formData: formData, subdepartment_id: editSubDepartmentID },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log("API response", response);
        if (
          response.data.status != null &&
          response.data.status === "success"
        ) {
          toast(response.data.msg);
          setFormData({
            DepartmentID: 0,
            SubDepartmentName: "",
            AgeLimit: 99,
          });
          setLoading(false);
        } else {
          console.log(response.data.error);
        }
      })
      .catch((ex) => {
        setLoading(false);
        if (ex.response) {
          console.log(ex.response);
        } else if (ex.request) {
          console.log(ex.request);
        } else {
          console.log("Error", ex.message);
        }
      });
    return;
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Edit SubDepartment</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">Edit SubDepartment</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <form name="frmSubDept" method="post" onSubmit={handleSubmit}>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Edit SubDepartment</h3>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label>Department ID*</label>
                      <select
                        className="form-control"
                        name="DepartmentID"
                        value={formData.DepartmentID}
                        onChange={handleChange}
                        required
                      >
                        <option value={""}>--SELECT--</option>
                        {departmentsArray.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.department_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="form-group">
                      <label>Sub Department Name*</label>
                      <input
                        type="text"
                        className="form-control"
                        name="SubDepartmentName"
                        value={formData.SubDepartmentName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Age Limit*</label>
                      <select
                        className="form-control"
                        name="AgeLimit"
                        value={formData.AgeLimit}
                        onChange={handleChange}
                        required
                      >
                        <option value={99}>99</option>
                        <option value={18}>18</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-footer mt-4">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-12">
                <input
                  type="Submit"
                  name="btnSubmit"
                  value="Submit"
                  className="btn btn-primary float-right mr-3"
                />
                <input
                  type="Submit"
                  name="btnCancel"
                  value="Cancel"
                  className="btn btn-secondary float-right mr-3"
                  onClick={(e) =>
                    (document.location.href = "../sub-departments")
                  }
                />
              </div>
            </div>
          </div>
        </section>
      </form>
    </>
  );
}
