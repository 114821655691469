import React, { useState, useEffect } from "react";
import logo from "../img/gloposnet-logo.svg";
import { BrowserRouter as Router, Link } from "react-router-dom";

function Header() {
  const [userName, setUserName] = useState("");

  useEffect(() => {
    setUserName(localStorage.getItem("name") || "Guest");
  }, []);

  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      <Link to="#" className="brand-link">
        <img src={logo} alt="logo" className="brand-image elevation-3" />
      </Link>
      <button
        type="button"
        className="navbar-toggler collapsed"
        data-bs-toggle="collapse"
        data-bs-target="#home-collapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
      </button>

      <ul className="navbar-nav ml-auto">
        <li className="nav-item d-none d-sm-inline-block">
          Hi {userName}
        </li>
      </ul>
    </nav>
  );
}

export default Header;
