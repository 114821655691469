import { React, useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Utils from "../../Utils";

export default function AddDepartment() {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    DepartmentName: "",
    AgeLimit: "",
    IsSeparateSalesInReport: false,
    IsAllowStaffDiscount: false,
    IsAllowDiscount: false,
  });
  const handleChange = (e) => {
    console.log(e.target.name + ":", e.target.value);
    const inputValue =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: inputValue,
      };
    });
  };

  useEffect(() => {}, [formData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(
        Utils.apiBaseURL + "departments/add",
        { formData: formData },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log("API response", response);
        if (
          response.data.status != null &&
          response.data.status === "success"
        ) {
          toast(response.data.msg);
          setFormData({
            DepartmentName: "",
            AgeLimit: "",
            IsSeparateSalesInReport: "",
            IsAllowStaffDiscount: "",
            IsAllowDiscount: "",
          });
          setLoading(false);
        } else {
          console.log(response.data.error);
        }
      })
      .catch((ex) => {
        setLoading(false);
        if (ex.response) {
          console.log(ex.response);
        } else if (ex.request) {
          console.log(ex.request);
        } else {
          console.log("Error", ex.message);
        }
      });
    return;
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Add Department</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">Add Department</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <form name="frmDept" method="post" onSubmit={handleSubmit}>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Add Department</h3>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label>Department Name *</label>
                      <input
                        type="text"
                        className="form-control"
                        name="DepartmentName"
                        value={formData.DepartmentName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Age Limit*</label>

                      <select
                        className="form-control"
                        name="AgeLimit"
                        value={formData.AgeLimit}
                        onChange={handleChange}
                        required
                      >
                        <option value={99}>99</option>
                        <option value={18}>18</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <div className="custom-control custom-checkbox">
                        <input
                          className="custom-control-input"
                          type="checkbox"
                          id="IsSeparateSalesInReport"
                          name="IsSeparateSalesInReport"
                          value={formData.IsSeparateSalesInReport}
                          defaultValue="false"
                          onChange={handleChange}
                        />
                        <label
                          for="IsSeparateSalesInReport"
                          className="custom-control-label"
                        >
                          Separate Sales In Report
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox">
                        <input
                          className="custom-control-input"
                          type="checkbox"
                          id="IsAllowStaffDiscount"
                          name="IsAllowStaffDiscount"
                          value={formData.IsAllowStaffDiscount}
                          defaultValue="false"
                          onChange={handleChange}
                        />
                        <label
                          for="IsAllowStaffDiscount"
                          className="custom-control-label"
                        >
                          Allow Staff Discount
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox">
                        <input
                          className="custom-control-input"
                          type="checkbox"
                          id="IsAllowDiscount"
                          name="IsAllowDiscount"
                          value={formData.IsAllowDiscount}
                          defaultValue="false"
                          onChange={handleChange}
                        />
                        <label
                          for="IsAllowDiscount"
                          className="custom-control-label"
                        >
                          Allow Discount
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content-footer mt-4">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-12">
                <input
                  type="Submit"
                  name="btnSubmit"
                  value="Submit"
                  className="btn btn-primary float-right mr-3"
                />
                <input
                  type="Submit"
                  name="btnCancel"
                  value="Cancel"
                  className="btn btn-secondary float-right mr-3"
                  onClick={(e) => (document.location.href = "../departments")}
                />
              </div>
            </div>
          </div>
        </section>
      </form>
    </>
  );
}
