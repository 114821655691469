function Footer() {
  return (
    <footer className="main-footer">
      <strong>
        Copyright &copy; 2022 <a href="/">Gloposnet</a>.
      </strong>
      All rights reserved.
    </footer>
  );
}

export default Footer;
