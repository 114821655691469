import { React, useEffect } from "react";
import Table from "../../hooks/Table";
import "datatables.net-dt/css/jquery.dataTables.min.css";
export default function UnverifiedProducts() {
  return (
    <>
      <section className="content pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title font-weight-bold">
                    Unverified Products
                  </h3>
                </div>

                <div className="card-body">
                  <Table />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
