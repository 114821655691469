import { React, useEffect, useState } from "react";
import "react-datetime/css/react-datetime.css";
import axios from "axios";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import Utils from "../../Utils";

export default function AddPromotion() {
  const defaultValue = new Date(2000, 2, 10, 13, 30, 0);
  const [formData, setFormData] = useState({
    PromotionType: "",
    PromotionName: "",
    InternalPromotionName: "",
    BuyQty: "",
    FreeQty: "",
    PromotionPrice: "",
    PromotionDiscount: "",
    IsPromotionBySupplier: "",
    IsForLoyalCustomerOnly: "",
    SupplierId: "",
    ProductBarcode: "",
    PromotionStartDate: "",
    PromotionEndDate: "",
    PromotionStartTime: "",
    PromotionEndTime: "",
    IsAreProductsCombined: "",
    ShopName: "",
    IsMonday: "",
    IsTuesday: "",
    IsWednesday: "",
    IsThuresday: "",
    IsFriday: "",
    IsSaturday: "",
    IsSunday: "",
    Datetime: "",
  });
  const [date1, setDate1] = useState(new Date());
  const [date2, setDate2] = useState(new Date());
  const [date3, setDate3] = useState(new Date());
  const [date4, setDate4] = useState(new Date());
  const [suppliers, setsuppliers] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setallsuppliers();
  }, []);
  const handleChange = (e) => {
    console.log(e.target.name + ":", e.target.value);
    const inputValue =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: inputValue,
      };
    });
  };

  const setallsuppliers = () => {
    setLoading(true);
    axios
      .post(
        Utils.apiBaseURL + "suppliers/getall",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setsuppliers(response.data.suppliers);
        console.log("setallsuppliers", response.data.suppliers);
        setLoading(false);
      })
      .catch((ex) => {
        setLoading(false);
        if (ex.response) {
          console.log(ex.response);
        } else if (ex.request) {
          console.log(ex.request);
        } else {
          console.log("Error", ex.message);
        }
      });
  };

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const handleSubmit = (e) => {
    console.log(formData);
  };

  const CalendarDemo = () => {
    let today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();
    let prevMonth = month === 0 ? 11 : month - 1;
    let prevYear = prevMonth === 11 ? year - 1 : year;
    let nextMonth = month === 11 ? 0 : month + 1;
    let nextYear = nextMonth === 0 ? year + 1 : year;

    const [date1, setDate1] = useState(null);
    const [date2, setDate2] = useState(null);
    const [date3, setDate3] = useState(null);
    const [date4, setDate4] = useState(null);
    const [date5, setDate5] = useState(null);
    const [date6, setDate6] = useState(null);
    const [date7, setDate7] = useState(null);
    const [date8, setDate8] = useState(null);
    const [date9, setDate9] = useState(null);
    const [date10, setDate10] = useState(null);
    const [date11, setDate11] = useState(null);
    const [date12, setDate12] = useState(null);
    const [date13, setDate13] = useState(null);
    const [date14, setDate14] = useState(null);
    const [dates1, setDates1] = useState(null);
    const [dates2, setDates2] = useState(null);

    let minDate = new Date();
    minDate.setMonth(prevMonth);
    minDate.setFullYear(prevYear);

    let maxDate = new Date();
    maxDate.setMonth(nextMonth);
    maxDate.setFullYear(nextYear);

    let invalidDates = [today];

    addLocale("es", {
      firstDayOfWeek: 1,
      dayNames: [
        "domingo",
        "lunes",
        "martes",
        "miércoles",
        "jueves",
        "viernes",
        "sábado",
      ],
      dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
      dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
      monthNames: [
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre",
      ],
      monthNamesShort: [
        "ene",
        "feb",
        "mar",
        "abr",
        "may",
        "jun",
        "jul",
        "ago",
        "sep",
        "oct",
        "nov",
        "dic",
      ],
      today: "Hoy",
      clear: "Limpiar",
    });

    const dateTemplate = (date) => {
      if (date.day > 10 && date.day < 15) {
        return (
          <strong style={{ textDecoration: "line-through" }}>{date.day}</strong>
        );
      }

      return date.day;
    };
  };

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1> Promotion Information</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">Add Promotion</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Promotion Information</h3>
                </div>

                <form>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Promotion Type ID*</label>
                          <select
                            className="form-control"
                            name="PromotionType"
                            value={formData.PromotionType}
                            onChange={handleChange}
                          >
                            <option>Buy N get N fRee</option>
                            <option>Buy N for xxx amount</option>
                            <option>Was xxx now xxxx</option>
                            <option>Any Item for xxx</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Promotion Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="PromotionName"
                            value={formData.PromotionName}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Internal Promotion Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="InternalPromotionName"
                            value={formData.InternalPromotionName}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Buy Qty</label>
                          <input
                            type="text"
                            className="form-control"
                            name="BuyQty"
                            value={formData.BuyQty}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Free Qty</label>
                          <input
                            type="text"
                            className="form-control"
                            name="FreeQty"
                            value={formData.FreeQty}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Promotion Price</label>
                          <input
                            type="text"
                            className="form-control"
                            name="PromotionPrice"
                            value={formData.PromotionPrice}
                            onChange={handleChange}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Promotion Discount</label>
                          <input
                            type="text"
                            className="form-control"
                            name="PromotionDiscount"
                            value={formData.PromotionDiscount}
                            onChange={handleChange}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <div className="custom-control custom-checkbox">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                              id="IsPromotionBySupplier"
                              name="IsPromotionBySupplier"
                              value={formData.IsPromotionBySupplier}
                              onChange={handleChange}
                            />
                            <label
                              for="IsPromotionBySupplier"
                              className="custom-control-label"
                            >
                              Is Promotion By Supplier
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <div className="custom-control custom-checkbox">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                              id="IsForLoyalCustomerOnly"
                              name="IsForLoyalCustomerOnly"
                              value={formData.IsForLoyalCustomerOnly}
                              onChange={handleChange}
                            />
                            <label
                              for="IsForLoyalCustomerOnly"
                              className="custom-control-label"
                            >
                              Is For Loyal Customer Only
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Supplier ID</label>
                      <select
                        className="form-control"
                        name="supplier_name"
                        value={formData.supplier_name}
                        onChange={handleChange}
                      >
                        <option value={""}>--SELECT--</option>
                        {suppliers.map((sup, index) => {
                          return (
                            <option key={index}>{sup.supplier_name}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </form>
              </div>

              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Promotion Period</h3>
                </div>

                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="IsAreProductsCombined"
                            name="IsAreProductsCombined"
                            value={formData.IsAreProductsCombined}
                            onChange={handleChange}
                          />
                          <label
                            for="IsAreProductsCombined"
                            className="custom-control-label"
                          >
                            Are Products Combined
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <label for="exampleInputRounded0">Product Barcode</label>
                      <input
                        type="text"
                        className="form-control"
                        name="ProductBarcode"
                        value={formData.ProductBarcode}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Promotion Period</h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label for="exampleInputRounded0">
                          Promotion Start
                        </label>

                        <div className="input-group">
                          <Calendar
                            id="icon"
                            value={formData.date1}
                            onChange={(e) => setDate1(e.value)}
                            showIcon
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label for="exampleInputRounded0">Promotion End</label>
                        <div className="input-group">
                          <Calendar
                            id="icon"
                            value={formData.date2}
                            onChange={(e) => setDate2(e.value)}
                            showIcon
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 pt-3">
                      <button type="submit" className="btn btn-info">
                        Life Time Promotion
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label for="exampleInputRounded0">
                          Promotion Start
                        </label>

                        <Calendar
                          id="time12"
                          value={formData.date3}
                          onChange={(e) => setDate3(e.value)}
                          timeOnly
                          hourFormat="12"
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label for="exampleInputRounded0">Promotion End</label>
                        <Calendar
                          id="time12"
                          value={formData.date4}
                          onChange={(e) => setDate4(e.value)}
                          timeOnly
                          hourFormat="12"
                        />
                      </div>
                    </div>
                    <div className="col-sm-4 pt-3">
                      <button type="submit" className="btn btn-info">
                        24 Hour Promotion
                      </button>
                    </div>
                  </div>
                  <h5>Day</h5>
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="form-group">
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="IsMonday"
                            name="IsMonday"
                            value={formData.IsMonday}
                            onChange={handleChange}
                          />
                          <label
                            for="IsMonday"
                            className="custom-control-label"
                          >
                            Monday
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="IsTuesday"
                            name="IsTuesday"
                            value={formData.IsTuesday}
                            onChange={handleChange}
                          />
                          <label
                            for="IsTuesday"
                            className="custom-control-label"
                          >
                            Tuesday
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="IsWednesday"
                            name="IsWednesday"
                            value={formData.IsWednesday}
                            onChange={handleChange}
                          />
                          <label
                            for="IsWednesday"
                            className="custom-control-label"
                          >
                            Wednesday
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="IsThuresday"
                            name="IsThuresday"
                            value={formData.IsThuresday}
                            onChange={handleChange}
                          />
                          <label
                            for="IsThuresday"
                            className="custom-control-label"
                          >
                            Thursday
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="IsFriday"
                            name="IsFriday"
                            value={formData.IsFriday}
                            onChange={handleChange}
                          />
                          <label
                            for="IsFriday"
                            className="custom-control-label"
                          >
                            Friday
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="IsSaturday"
                            name="IsSaturday"
                            value={formData.IsSaturday}
                            onChange={handleChange}
                          />
                          <label
                            for="IsSaturday"
                            className="custom-control-label"
                          >
                            Saturday
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="IsSunday"
                            name="IsSunday"
                            value={formData.IsSunday}
                            onChange={handleChange}
                          />
                          <label
                            for="IsSunday"
                            className="custom-control-label"
                          >
                            Sunday
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Shop Promo</h3>
                </div>

                <div className="card-body p-0">
                  <table className="table">
                    <thead>
                      <tr>
                        <th></th> <th>Shop Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="form-group">
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id="customCheckbox2"
                                value={formData.ShopName}
                                onChange={handleChange}
                              />
                              <label
                                for="customCheckbox2"
                                className="custom-control-label"
                              ></label>
                            </div>
                          </div>
                        </td>
                        <td>
                          {" "}
                          <input
                            type="text"
                            className="form-control"
                            name="Product Barcode"
                            value={formData.ShopName}
                            onChange={handleChange}
                            disabled
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <div className="form-group">
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id="ShopName"
                                value={formData.ShopName}
                                onChange={handleChange}
                              />
                              <label
                                for="ShopName"
                                className="custom-control-label"
                              ></label>
                            </div>
                          </div>
                        </td>{" "}
                        <td>
                          {" "}
                          <input
                            type="text"
                            className="form-control"
                            name="Product Barcode"
                            value={formData.ShopName}
                            onChange={handleChange}
                            disabled
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <div className="form-group">
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id="ShopName"
                                value={formData.ShopName}
                                onChange={handleChange}
                              />
                              <label
                                for="ShopName"
                                className="custom-control-label"
                              ></label>
                            </div>
                          </div>
                        </td>{" "}
                        <td>
                          {" "}
                          <input
                            type="text"
                            className="form-control"
                            name="ShopName"
                            value={formData.ShopName}
                            onChange={handleChange}
                            disabled
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <div className="form-group">
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id="ShopName"
                                value={formData.ShopName}
                                onChange={handleChange}
                              />
                              <label
                                for="ShopName"
                                className="custom-control-label"
                              ></label>
                            </div>
                          </div>
                        </td>{" "}
                        <td>
                          {" "}
                          <input
                            type="text"
                            className="form-control"
                            name="Product Barcode"
                            disabled
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content-footer mt-4">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-12 py-4">
              <input
                type="Submit"
                name="btnSubmit"
                value="Submit"
                className="btn btn-primary float-right mr-3"
                onClick={handleSubmit}
              />
              <input
                type="Submit"
                name="btnCancel"
                value="Cancel"
                className="btn btn-success float-right mr-3"
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
