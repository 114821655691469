import React, { Component } from "react";
import Header from "../components/header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/footer";
export default class main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showTopMenuBar: true,
      showLeftMenuBar: false,
    };

    this.toggleLeftMenu = this.toggleLeftMenu.bind(this);
  }

  toggleLeftMenu() {
    this.setState({ showLeftMenuBar: !this.state.showLeftMenuBar });
  }
  render() {
    return (
      <React.Fragment>
        <Header onBurgerMenuClicked={this.toggleLeftMenu} />
        <div className="d-flex h-100">
          <Sidebar onBurgerMenuClicked={this.toggleLeftMenu}></Sidebar>
          <div className="wrapper w-100">{this.props.children}</div>
        </div>
        <Footer onBurgerMenuClicked={this.toggleLeftMenu} />
      </React.Fragment>
    );
  }
}
