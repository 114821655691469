import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Ripple } from "primereact/ripple";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Column } from "primereact/column";
import axios from "axios";
import Utils from "../../Utils";

//import PromotionsGrid from "../../hooks/Promotionsgrid";
//import JSONPromotions from "./Promotions.json";
export default function PromotionsProducts() {
  const [error, setError] = React.useState();
  const [promotions, setPromotions] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageInputTooltip, setPageInputTooltip] = React.useState(
    "Press 'Enter' key to go to this page."
  );
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 100,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: {
      product_barcode: { value: "", matchMode: "contains" },
      product_name: { value: "", matchMode: "contains" },
      product_plu: { value: "", matchMode: "contains" },
      department_name: { value: "", matchMode: "contains" },
      sub_department_name: { value: "", matchMode: "contains" },
      product_selling_price: { value: "", matchMode: "contains" },
    },
  });

  let loadLazyTimeout = null;

  useEffect(() => {
    loadLazyData();
  }, [lazyParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadLazyData = () => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    //imitate delay of a backend call
    loadLazyTimeout = setTimeout(() => {
      axios
        .post(
          Utils.apiBaseURL + "products",
          { lazyParams: JSON.stringify(lazyParams) },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          setPromotions(response.data.products);
          setTotalRecords(response.data.totalrecords);
          setLoading(false);
        })
        .catch((ex) => {
          setLoading(false);
          if (ex.response) {
            console.log(ex.response);
          } else if (ex.request) {
            console.log(ex.request);
          } else {
            console.log("Error", ex.message);
          }
        });
    }, Math.random() * 1000 + 250);
  };
  const onPage = (event) => {
    setLazyParams(event);
  };

  const onSort = (event) => {
    setLazyParams(event);
  };

  const onFilter = (event) => {
    event["first"] = 0;
    setLazyParams(event);
  };

  const actionBodyTemplate = () => {
    return <Button type="button" icon="pi pi-cog"></Button>;
  };
  const paginatorLeft = (
    <Button type="button" icon="pi pi-refresh" className="p-button-text" />
  );
  const paginatorRight = (
    <Button type="button" icon="pi pi-cloud" className="p-button-text" />
  );

  return (
    <>
      <section className="content pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title font-weight-bold">Promotions</h3>
                  <Link to="/add-promotions-products">
                    <button
                      type="button"
                      className="btn btn-primary float-right"
                    >
                      Add Promotions Products
                    </button>
                  </Link>
                </div>

                <div className="card-body">
                  <DataTable
                    value={promotions}
                    showGridlines
                    size="small"
                    responsiveLayout="scroll"
                    paginator
                    lazy
                    first={lazyParams.first}
                    rows={lazyParams.rows}
                    totalRecords={totalRecords}
                    onPage={onPage}
                    onFilter={onFilter}
                    filters={lazyParams.filters}
                    loading={loading}
                    filterDisplay="row"
                    onSort={onSort}
                    sortField={lazyParams.sortField}
                    sortOrder={lazyParams.sortOrder}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    rowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                  >
                    <Column field="id" header="ID" sortable filter />
                    <Column
                      field="product_barcode"
                      header="Barcode"
                      sortable
                      filter
                    />
                    <Column field="product_plu" header="PLU" sortable filter />
                    <Column
                      field="product_name"
                      header="Name"
                      sortable
                      filter
                    />
                    <Column
                      field="department_name"
                      header="Department"
                      sortable
                      filter
                    />
                    <Column
                      field="sub_department_name"
                      header="Sub Department"
                      sortable
                      filter
                    />
                    <Column
                      field="product_selling_price"
                      header="Price"
                      sortable
                      filter
                    />
                    <Column
                      headerStyle={{ width: "4rem", textAlign: "center" }}
                      bodyStyle={{ textAlign: "center", overflow: "visible" }}
                      body={actionBodyTemplate}
                    />
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
