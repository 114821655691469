import { React, useEffect, useState } from "react";
import axios from "axios";
import Utils from "../../Utils";

export default function AddVat() {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    VatName: "",
    VatValue: "",
  });
  const handleChange = (e) => {
    console.log(e.target.name + ":", e.target.value);
    const inputValue =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: inputValue,
      };
    });
  };

  const setallvat = () => {
    setLoading(true);
    axios
      .post(
        Utils.apiBaseURL + "vat/getall",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setallvat(response.data.vat);
        console.log("setallvat", response.data.vat);
        setLoading(false);
      })
      .catch((ex) => {
        setLoading(false);
        if (ex.response) {
          console.log(ex.response);
        } else if (ex.request) {
          console.log(ex.request);
        } else {
          console.log("Error", ex.message);
        }
      });
  };

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const handleSubmit = (e) => {
    console.log(formData);
  };

  return (
    <>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Add Vat</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">Add Vat</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Add Vat</h3>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label>Vat Name*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="VatName"
                      value={formData.VatName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Vat Value</label>
                    <input
                      type="text"
                      className="form-control"
                      name="VatValue"
                      value={formData.VatValue}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="content-footer mt-4">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-12">
              <input
                type="button"
                name="btnSubmit"
                value="Submit"
                className="btn btn-primary float-right mr-3"
              />
              <input
                type="button"
                name="btnCancel"
                value="Cancel"
                className="btn btn-success float-right mr-3"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
