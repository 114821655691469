import React, { Component } from "react";

export default class AuthLayout extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="login-page"> {this.props.children} </div>{" "}
      </React.Fragment>
    );
  }
}
