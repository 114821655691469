import axios from "axios";
import React, { Component } from "react";
import DataTables from "./DataTable";
class Table extends Component {
  constructor(props) {
    super(props);
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    const URL = "http://192.168.2.225:3000/api/products";
    const fields = [
      { title: "Barcode", data: "product_barcode" },
      { title: "PLU", data: "product_plu" },
      { title: "Name", data: "product_name" },
      { title: "Department.", data: "department_id" },
      { title: "Sub Department", data: "sub_department_id" },
      { title: "Price", data: "product_selling_price" },
    ];

    this.state = {
      data: [],
      columns: fields,
    };
  }
  componentDidMount() {
    axios
      .get("", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => {
        this.setState({ data: response.data.products }, () =>
          console.log("after set state" + this.state.data)
        );
      })
      .catch((ex) => {
        if (ex.response) {
          console.log(ex.response);
        } else if (ex.request) {
          console.log(ex.request);
        } else {
          console.log("Error", ex.message);
        }
      });
  }
  deleteRow = (id) => {
    const filteredData = this.state.data.filter((i) => i.id !== id);
    this.setState({ data: filteredData });
  };
  render() {
    return <DataTables columns={this.state.columns} data={this.state.data} />;
  }
}
export default Table;
